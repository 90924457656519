export default function InstagramFeeds({ blok }) {
  return blok.instagram.images.length > 0 ? (
    <>
      <h2 className="my-8 text-black text-l1 lg:text-l2 relative z-10 overflow-hidden text-center before:absolute before:top-1/2 before:overflow-hidden before:w-full before:h-0.5 before:content-['\a0'] before:bg-green-apple before:left-0 before:-z-20 px-12">
        <span className="bg-white block w-auto sm:w-max mx-auto sm:border-x-[40px] border-white">
          Follow Us on Instagram
        </span>
      </h2>

      <section className="grid grid-cols-2 md:grid-cols-4 2xl:grid-cols-8 overflow-x-clip">
        {blok.instagram.images.map((feed) => (
          <Img key={feed.id} {...feed} />
        ))}
      </section>
    </>
  ) : null
}

const Img = ({ id, caption, permalink, imagePath }) => {
  return (
    <a
      href={permalink}
      target="_blank"
      rel="noreferrer"
      className="w-full relative hover:scale-105 transition-all duration-200 z-0 hover:z-50 hover:shadow-lg hover:left-0 hover:right-0 hover:shadow-gray-dark"
    >
      <img id={id} src={imagePath} alt={caption} width="400" className="object-cover aspect-square w-full" />
    </a>
  )
}
